import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n


import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import '@/icons' // icon
import '@/permission' // permission control
import htmlToPdf from "./utils/html2pdf.js"

//在路由守卫地方从url中获取钉钉回调函数中的code值
import { dingLoginGetToken } from '@/api/login'

//引入过滤器
import filter from './utils/filter'
for (let key in filter){ 
  Vue.filter(key, filter[key])
}



// 引入大数据表格，原框架表格太卡
import ElBigdataTable from 'vue-elementui-bigdata-table/dist/vue-elementui-bigdata-table.js'
Vue.use(ElBigdataTable)


// 引入表格下拉加载指令 现有表格不支持
import elTableInfiniteScroll from 'el-table-infinite-scroll';
Vue.use(elTableInfiniteScroll);

router.beforeEach((to, from, next) => {
    let code = to.query.code;
    if (code) {
        dingLoginGetToken({ code: code }).then((res) => {
            if (res.data.code == 0) {
                localStorage.setItem('access_token', res.data.data.access_token);
            } else {
                this.$message.error(res.data.msg);
            }
            next()
        }).catch((error) => {
            next()
        })
    } else {
        next();
    }

})

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(ElementUI)

Vue.config.productionTip = false

import VCharts from 'v-charts'
Vue.use(VCharts)
Vue.use(htmlToPdf)

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
