<template>
    <div v-if="!item.hidden">
        <template v-if="item.children.length==0">
            <app-link :to="item.resourceUrl" v-if="item.resourceUrl">
                <el-menu-item  :index="item.resourceUrl" :class="{'submenu-title-noDropdown':!isNest}">
                    <item :icon="item.resourceIcon" :title="item.resourceName" :activeIcon="item.resourceIconActive" />
                </el-menu-item>
            </app-link>
        </template>
        <el-submenu v-else ref="subMenu" :index='item.index'  popper-append-to-body >
            <template slot="title">
                <item  :icon="item.resourceIcon" :title="item.resourceName" :activeIcon="item.resourceIconActive" />
            </template>
            <sidebar-item
                v-for="child in item.children"
                :key="child.id"
                :is-nest="true"
                :item="child"
                :base-path="resolvePath(item.resourceUrl)"
                class="nest-menu"
            />
        </el-submenu>
        
    </div>
</template>

<script>
import path from 'path'
import Item from './Item'
import AppLink from './Link'
import { isExternal } from '@/utils/validate'


export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    parentIndex: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
  resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
