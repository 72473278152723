
const filter = {

    // 金额格式化
    money: (val) => {
        if(isNaN(Number(val)||!val)){
            return Number(val)
        }  
    
        return (Number(val)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
       
    },

    // 数字千分位格式化
    toThousands(num) {
        if(!num) {
            return 0
        }
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    }


}

export default filter