import request from '@/utils/request'
import settings from '../settings';
/**
 * 人员管理
 */
export function getPersonList(params) {
    //获取人员列表
    return request({
        url: settings.userService + '/user/list',
        method: 'get',
        params
    })
}
export function resetPass(params) {
    //重置密码
    return request({
        url: settings.userService + '/user/password/reset/' + params.id,
        method: 'put'
    })
}
export function distributionRole(params) {
    //给人员分配角色
    return request({
        url: settings.userService + '/role/distribution',
        method: 'post',
        data: params
    })
}
//修改人员配置
export function changeSetting(params) {
    return request({
        url: settings.userService + '/user/configuration',
        method: 'post',
        data: params
    })
}
//获取人员配置
export function getSetting(params) {
    return request({
        url: settings.userService + '/user/configuration/' + params.id,
        method: 'get',
    })
}
//获取所有人员列表
export function getAllPerson() {
    return request({
        url: settings.userService + '/user/selected/list',
        method: 'post'
    })
}
//获取发行部人员列表
export function getFaxingPerson(params) {
    return request({
        url: settings.userService + '/user/selectedContainsKey/list',
        method: 'post',
        data: params
    })
}
//更新钉钉人员
export function updateListApi() {
    return request({
        url: settings.userService + '/dingtalk/loadUser',
        method: 'post'
    })
}
/**
 * 角色配置
 */
export function getRole(params) {
    //获取角色
    return request({
        url: settings.userService + '/role/list',
        method: 'get',
        data: params
    })
}
export function addRole(params) {
    //新增角色
    return request({
        url: settings.userService + '/role/add',
        method: 'post',
        data: params
    })
}
export function editRoleName(params) {
    //编辑角色
    return request({
        url: settings.userService + '/role',
        method: 'put',
        data: params
    })
}
export function deleteRole(params) {
    //删除角色
    return request({
        url: settings.userService + '/role/' + params.id,
        method: 'delete'
    })
}
export function getResourceByRoleId(params) {
    //根据角色id获取相应的资源
    return request({
        url: settings.userService + '/resource/queryRoleResource/' + params.id,
        method: 'get'
    })
}
export function getResource(params) {
    //获取资源
    return request({
        url: settings.userService + '/resource',
        method: 'get',
        params
    })
}
export function getCurrentResource() {
    //当前人员获取资源
    return request({
        url: settings.userService + '/user/resource',
        method: 'get'
    })
}
export function setResource(params) {
    //获取资源
    return request({
        url: settings.userService + '/resource/distribution',
        method: 'post',
        data: params
    })
}

/**
 * 类型配置
 */
export function getSortMsg(params) {
    //获取分类信息
    return request({
        url: settings.userService + '/classification',
        method: 'get',
        params
    })
}
export function addSortMsg(params) {
    //新增分类信息
    return request({
        url: settings.userService + '/classification',
        method: 'post',
        data: params
    })
}
export function editSortMsg(params) {
    //编辑分类信息
    return request({
        url: settings.userService + '/classification',
        method: 'put',
        data: params
    })
}
export function deleteSortMsg(params) {
    //删除分类信息
    return request({
        url: settings.userService + '/classification/' + params.id,
        method: 'delete'
    })
}
// 平台配置
export function getPlateFormList(params) {
    //获取跟进平台列表
    return request({
        url: settings.userService + '/platform/list',
        method: 'get',
        params
    })
}
export function getNewPlateFormList(params) {
    //获取节目单详情中的平台列表
    return request({
        url: settings.userService + '/platform/aplist',
        method: 'get',
        params
    })
}
export function addPlateForm(params) {
    //新增平台
    return request({
        url: settings.userService + '/platform/add',
        method: 'post',
        data: params
    })
}
export function deletePlateForm(params) {
    //删除平台信息
    return request({
        url: settings.userService + '/platform/delete/' + params.id,
        method: 'delete'
    })
}
export function editPlateForm(params) {
    //编辑平台信息
    return request({
        url: settings.userService + '/platform/update',
        method: 'put',
        data: params
    })
}
export function getAllPlateForm(params) {
    //获取所有平台信息
    return request({
        url: settings.userService + '/platform/name?name=' + params.name,
        method: 'post'
    })
}
//授权平台
export function getAuthPlateFormList(params) {
    return request({
        url: settings.userService + '/authorizePlatform/list',
        method: 'get',
        params
    })
}
export function editAuthPlateForm(params) {
    return request({
        url: settings.userService + '/authorizePlatform/update',
        method: 'put',
        data: params
    })
}
export function addAuthPlateForm(params) {
    return request({
        url: settings.userService + '/authorizePlatform/add',
        method: 'post',
        data: params
    })
}
export function deleteAuthPlateForm(params) {
    return request({
        url: settings.userService + '/authorizePlatform/delete/' + params.id,
        method: 'delete'
    })
}
// 律所配置
export function getLawList(params) {
    //获取律所列表
    return request({
        url: settings.userService + '/law/list',
        method: 'get',
        params
    })
}
export function editLaw(params) {
    //编辑律所配置
    return request({
        url: settings.userService + '/law/update',
        method: 'put',
        data: params
    })
}
export function addLaw(params) {
    //新增律所配置
    return request({
        url: settings.userService + '/law/add',
        method: 'post',
        data: params
    })
}
export function deleteLaw(params) {
    //删除律所
    return request({
        url: settings.userService + '/law/delete/' + params.id,
        method: 'delete'
    })
}
export function getAllLaw() {
    //不分页获取所有律所
    return request({
        url: settings.userService + '/law',
        method: 'get'
    })
}
//运营商侧列表
export function operatorSide() {
    return request({
        url: settings.userService + '/contract/program/operatorSide/list',
        method: 'get'
    })
}
//省份配置
export function getAllProvice() {
    return request({
        url: settings.userService + '/province/list',
        method: 'get'
    })
}
export function configProvinceLevel(params) {
    return request({
        url: settings.userService + '/province/level',
        method: 'put',
        data: params
    })
}
// 获取维权配置 - 维权回收配置
export function queryConfigurationInformation() {
    return request({
        url: settings.userService + '/recyclingTime/queryConfigurationInformation',
        method: 'get'
    })
}
// 获取维权配置 - 维权回收配置
export function saveConfigurationInformation(params) {
    return request({
        url: settings.userService + '/recyclingTime/saveConfigurationInformation',
        method: 'post',
        data: params
    })
}
// 阶段筛选返回最大阶段
export function maximumStage(params) {
    return request({
        url: settings.userService + '/rightsManagement/maximumStage',
        method: 'get',
        data: params
    })
}
// 获取所有阶段列表 - options
export function getAllPlatformStageName(params) {
    return request({
        url: settings.userService + '/rightsManagement/getAllPlatformStageName',
        method: 'post',
        data: params
    })
}