var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative", class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": true,
                "active-text-color": "#fff",
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.routes, function (route) {
              return _c("sidebar-item", {
                key: route.id,
                attrs: { item: route, "base-path": route.resourceUrl },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.system_type == "copyright"
        ? _c("img", {
            staticClass: "logo-back",
            attrs: {
              src: require("../../../assets/index_images/logo-back-icon2.png"),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.system_type == "safeguard"
        ? _c("img", {
            staticClass: "logo-back",
            attrs: {
              src: require("../../../assets/index_images/logo-back-icon.png"),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }