import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
        path: '/login',
        component: () =>
            import ('@/views/login/index'),
        hidden: true
    },

    {
        path: '/404',
        component: () =>
            import ('@/views/404'),
        hidden: true
    },

    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [{
            path: 'dashboard',
            name: '首页',
            component: () =>
                import ('@/views/dashboard/index'),
            meta: { title: '首页', icon: 'dashboard' }
        }]
    },

    // {
    //     path: '/mediaDashboard',
    //     component: Layout,
    //     redirect: '/mediaDashboard',
    //     children: [{
    //         path: 'mediaDashboard',
    //         name: '介质管理',
    //         component: () => import('@/views/media/mediaDashboard'),
    //         meta: { title: '介质管理', icon: 'dashboard' }
    //     }]
    // },

    {
        path: '/media',
        component: Layout,
        redirect: '/media/mediaDashboard',
        name: 'media',
        meta: { title: '介质管理' },
        children: [{
                path: 'mediaDashboard',
                name: '介质管理',
                component: () =>
                    import ('@/views/media/mediaDashboard'),
                meta: { title: '介质管理' }
            },
            {
                path: 'plate',
                name: 'plate',
                component: () =>
                    import ('@/views/media/plate'),
                meta: { title: '硬盘管理' }
            },
            {
                path: 'local',
                name: 'local',
                component: () =>
                    import ('@/views/media/local'),
                meta: { title: '本地管理' }
            },
            {
                path: 'search',
                component: () =>
                    import ('@/views/media/search/index'),
                meta: { title: '片库检索' },
                children: [{
                        path: '/',
                        name: '片库搜索',
                        component: () =>
                            import ('@/views/media/search/search'),
                    },
                    {
                        path: 'program',
                        component: () =>
                            import ('@/views/media/search/program/index'),
                        meta: { title: '节目列表' },
                        children: [{
                                path: '/',
                                name: 'list',
                                component: () =>
                                    import ('@/views/media/search/program/list'),
                            },
                            {
                                path: 'detail',
                                component: () =>
                                    import ('@/views/media/search/program/detail/index'),
                                meta: { title: '节目档案' },
                                children: [{
                                        path: '/',
                                        name: '节目档案',
                                        component: () =>
                                            import ('@/views/media/search/program/detail/info'),
                                    },
                                    {
                                        path: 'edit',
                                        name: '节目编辑',
                                        component: () =>
                                            import ('@/views/media/search/program/detail/edit'),
                                        meta: { title: '节目编辑' }
                                    },
                                    {
                                        path: 'poster',
                                        name: '节目海报',
                                        component: () =>
                                            import ('@/views/media/search/program/detail/poster'),
                                        meta: { title: '节目海报' }
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },

            {
                path: 'transcoding',
                component: () =>
                    import ('@/views/media/transcoding/index'),
                meta: { title: '介质转码' },
                children: [{
                        path: '/',
                        name: '介质转码',
                        component: () =>
                            import ('@/views/media/transcoding/list'),
                    },
                    {
                        path: 'detail',
                        name: '工作提交',
                        component: () =>
                            import ('@/views/media/transcoding/detail'),
                        meta: { title: '工作提交' }
                    }
                ]
            },

        ]
    },

    {
        path: '/rights',
        component: Layout,
        redirect: '/rights/rightsDashboard',
        name: '维权业务统计',
        meta: { title: '维权业务统计', icon: 'example' },
        children: [
            // 维权业务 - 欢迎页
            {
                path: 'rightsDashboard',
                name: '维权业务',
                component: () =>
                    import ('@/views/rights/rightsDashboard'),
                meta: { title: '维权业务' }
            },
            // 本部的平台管理
            {
                path: 'platformOfAdmin',
                name: '平台管理',
                component: () =>
                    import ('@/views/rights/platformOfAdmin'),
                meta: { title: '平台管理', icon: 'form' }
            },
            // 律所的平台管理
            {
                path: 'platformOfLaw',
                name: '视频上传',
                component: () =>
                    import ('@/views/rights/platformOfLaw'),
                meta: { title: '视频上传', icon: 'form' }
            },
            // 实习生的首页面
            {
                path: 'collection',
                name: '内容分拣',
                component: () =>
                    import ('@/views/rights/collection'),
                meta: { title: '内容分拣', icon: 'form' }
            },
            // 根据视频录入内容的工作台，也是独立页面，其他地方会用到
            {
                path: 'investigate',
                name: '节目排查',
                component: () =>
                    import ('@/views/rights/investigate'),
                meta: { title: '节目排查', icon: 'form' }
            },
            {
                path: 'investigateList',
                name: '节目排查列表',
                component: () =>
                    import ('@/views/rights/investigateList'),
                meta: { title: '节目排查列表', icon: 'form' }
            },
            {
                path: 'mediaQueryAutoForAdmin',
                component: () =>
                    import ('@/views/rights/mediaQueryAutoForAdmin/index'),
                meta: { title: '媒介查询', icon: 'form' },
                children: [{
                        path: '/',
                        name: '列表',
                        component: () =>
                            import ('@/views/rights/mediaQueryAutoForAdmin/mediaQueryAutoForAdmin'),
                        meta: { title: '列表' },
                    },
                    {
                        path: 'infoEdit',
                        name: '信息补充',
                        component: () =>
                            import ('@/views/rights/mediaQueryAutoForAdmin/infoEdit'),
                        meta: { title: '信息补充' },
                    },
                ]
            },
            // 自动碰撞的结果内容 - 律所
            {
                path: 'mediaQueryAutoForLaw',
                component: () =>
                    import ('@/views/rights/mediaQueryAutoForLaw/index'),
                meta: { title: '维权执行', icon: 'form' },
                children: [{
                    path: '/',
                    name: '维权执行列表',
                    component: () =>
                        import ('@/views/rights/mediaQueryAutoForLaw/mediaQueryAutoForLaw')
                }]
            },
            {
                path: 'protectionSupport',
                component: () =>
                    import ('@/views/rights/protectionSupport/index'),
                meta: { title: '维权支持', icon: 'form' },
                children: [{
                    path: '/',
                    name: '维权节目确认',
                    component: () =>
                        import ('@/views/rights/protectionSupport/protectionSupport')
                }]
            },
            {
                path: 'temporaryCollision',
                component: () =>
                    import ('@/views/rights/temporaryCollision/index'),
                meta: { title: '版权排查', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/rights/temporaryCollision/temporaryCollision'),
                        meta: { title: '列表' },
                    },
                    {
                        path: 'temporaryCollisionInfo',
                        name: '详情',
                        component: () =>
                            import ('@/views/rights/temporaryCollision/temporaryCollisionInfo'),
                        meta: { title: '详情' },
                    },
                    {
                        path: 'statisticsPage',
                        name: '生成统计',
                        component: () =>
                            import ('@/views/rights/temporaryCollision/statisticsPage'),
                        meta: { title: '生成统计' },
                    },
                ]
            },
            {
                path: 'videoFollowUpAndRelease',
                component: () =>
                    import ('@/views/rights/videoFollowUpAndRelease/index'),
                meta: { title: '视频下发跟进', icon: 'form' },
                children: [{
                    path: '/',
                    name: '内容',
                    component: () =>
                        import ('@/views/rights/videoFollowUpAndRelease/videoFollowUpAndRelease')
                }]
            },
            {
                path: 'videoSortingFollowUp',
                component: () =>
                    import ('@/views/rights/videoSortingFollowUp/index'),
                meta: { title: '视频分拣跟进', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/rights/videoSortingFollowUp/videoSortingFollowUp'),
                        meta: { title: '视频分拣跟进' },
                    },
                    {
                        path: 'dataChart',
                        name: '视频分拣详情统计',
                        component: () =>
                            import ('@/views/rights/videoSortingFollowUp/dataChart'),
                        meta: { title: '视频分拣详情统计' },
                    },
                ]
            },
            {
                path: 'personnelSorting',
                component: () =>
                    import ('@/views/rights/personnelSorting/index'),
                meta: { title: '人员分拣详情', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/rights/personnelSorting/personnelSorting'),
                        meta: { title: '人员分拣详情' },
                    },
                    {
                        path: 'dataChart',
                        name: '人员分拣详情统计',
                        component: () =>
                            import ('@/views/rights/personnelSorting/dataChart'),
                        meta: { title: '人员分拣详情统计' },
                    },
                ]
            },
            {
                path: 'rightsProtectionCheck',
                component: () =>
                    import ('@/views/rights/rightsProtectionCheck/index'),
                meta: { title: '确权排查监测', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/rights/rightsProtectionCheck/rightsProtectionCheck'),
                        meta: { title: '确权排查监测' },
                    },
                    // {
                    //     path: 'dataChart',
                    //     name: '侵权统计',
                    //     component: () => import('@/views/rights/rightsProtectionCheck/dataChart'),
                    //     meta: { title: '侵权统计' },
                    // },
                ]
            },

            // {
            //     path: 'videoSortingFollowUp',
            //     name: '视频分拣跟进',
            //     component: () => import('@/views/rights/videoSortingFollowUp/videoSortingFollowUp'),
            //     meta: { title: '视频分拣跟进', icon: 'form' }
            // },
            // {
            //     path: 'dataChart',
            //     name: '分拣详情统计',
            //     component: () => import('@/views/rights/videoSortingFollowUp/dataChart'),
            //     meta: { title: '分拣详情统计', icon: 'form' }
            // },
        ]
    },

    {
        path: '/settlement',
        component: Layout,
        name: 'settlement',
        meta: { title: '维权结算管理' },
        children: [{
                path: 'settleIndex',
                name: '欢迎页',
                component: () =>
                    import ('@/views/settlement/settleIndex'),
                meta: { title: '欢迎页', icon: 'form' }
            },
            {
                path: 'reconciliation',
                name: '和解节目列表',
                component: () =>
                    import ('@/views/settlement/reconciliation'),
                meta: { title: '和解节目列表', icon: 'form' }
            },
            {
                path: 'suit',
                name: '诉讼节目列表',
                component: () =>
                    import ('@/views/settlement/suit'),
                meta: { title: '诉讼节目列表', icon: 'form' }
            },
            {
                path: 'suitpay',
                name: '维权收款列表',
                component: () =>
                    import ('@/views/settlement/suitpay'),
                meta: { title: '维权收款列表', icon: 'form' }
            },
            {
                path: 'lawpay',
                name: '律所收款列表',
                component: () =>
                    import ('@/views/settlement/lawpay'),
                meta: { title: '律所收款列表', icon: 'form' }
            },
            {
                path: 'buyout',
                name: '买断收款列表',
                component: () =>
                    import ('@/views/settlement/buyout'),
                meta: { title: '买断收款列表', icon: 'form' }
            },
            {
                path: 'guaranteed',
                name: '保底收款列表',
                component: () =>
                    import ('@/views/settlement/guaranteed'),
                meta: { title: '保底收款列表', icon: 'form' }
            },
            {
                path: 'splitpay',
                name: '分账收款列表',
                component: () =>
                    import ('@/views/settlement/splitpay'),
                meta: { title: '分账收款列表', icon: 'form' }
            }
        ]
    },

    {
        path: '/copyrightSettlement',
        component: Layout,
        redirect: '/copyrightSettlement/copyrightSettlementDashboard',
        name: '版权结算管理',
        meta: { title: '版权结算管理', icon: 'example' },
        children: [
            // 版权结算管理 - 欢迎页  
            {
                path: 'copyrightSettlementDashboard',
                name: '业务配置',
                component: () =>
                    import ('@/views/copyrightSettlement/copyrightSettlementDashboard'),
                meta: { title: '业务配置' }
            },
            {
                path: 'authorizedCollection',
                name: '授权收款列表',
                component: () =>
                    import ('@/views/copyrightSettlement/authorizedCollection'),
                meta: { title: '授权收款列表', icon: 'form' }
            },
            {
                path: 'splitProcessing',
                name: '拆账处理',
                component: () =>
                    import ('@/views/copyrightSettlement/splitProcessing'),
                meta: { title: '拆账处理', icon: 'form' }
            },
            {
                path: 'splitConfirmation',
                name: '拆账确认列表',
                component: () =>
                    import ('@/views/copyrightSettlement/splitConfirmation'),
                meta: { title: '拆账确认列表', icon: 'form' }
            },
            {
                path: 'splitConfirmationAdjust',
                name: '拆账确认',
                component: () =>
                    import ('@/views/copyrightSettlement/splitConfirmationAdjust'),
                meta: { title: '拆账确认', icon: 'form' }
            },
            {
                path: 'proxyPay',
                name: '代理商付款列表',
                component: () =>
                    import ('@/views/copyrightSettlement/proxyPay'),
                meta: { title: '代理商付款列表', icon: 'form' }
            },
            {
                path: 'buyout',
                name: '买断付款列表',
                component: () =>
                    import ('@/views/copyrightSettlement/buyout'),
                meta: { title: '买断付款列表', icon: 'form' }
            },
            {
                path: 'guaranteed',
                name: '保底付款列表',
                component: () =>
                    import ('@/views/copyrightSettlement/guaranteed'),
                meta: { title: '保底付款列表', icon: 'form' }
            },
            {
                path: 'splitpay',
                name: '分账付款列表',
                component: () =>
                    import ('@/views/copyrightSettlement/splitpay'),
                meta: { title: '分账付款列表', icon: 'form' }
            },
        ]
    },

    {
        path: '/statistics',
        component: Layout,
        name: 'statistics',
        meta: { title: '维权统计BI' },
        children: [{
                path: 'index',
                name: '维权概况统计',
                component: () =>
                    import ('@/views/statistics/index'),
                meta: { title: '维权概况统计' }
            },
            {
                path: 'copy',
                name: '版权方统计',
                component: () =>
                    import ('@/views/statistics/copy'),
                meta: { title: '版权方统计' }
            },
            {
                path: 'plateform',
                name: '平台方统计',
                component: () =>
                    import ('@/views/statistics/plateform'),
                meta: { title: '平台方统计' }
            },
            {
                path: 'report',
                name: '版权方报告',
                component: () =>
                    import ('@/views/statistics/report'),
                meta: { title: '版权方报告' }
            }
        ]
    },

    {
        path: '/configure',
        component: Layout,
        redirect: '/configure/configureDashboard',
        name: 'configure',
        meta: { title: '业务配置' },
        children: [
            // 业务配置 - 欢迎页
            {
                path: 'configureDashboard',
                name: '业务配置',
                component: () =>
                    import ('@/views/configure/configureDashboard'),
                meta: { title: '业务配置' }
            },
            {
                path: 'person',
                name: '人员配置',
                component: () =>
                    import ('@/views/configure/person'),
                meta: { title: '人员配置' }
            },
            {
                path: 'role',
                name: '角色配置',
                component: () =>
                    import ('@/views/configure/role'),
                meta: { title: '角色配置' }
            },
            {
                path: 'classify',
                name: '类型配置',
                component: () =>
                    import ('@/views/configure/classify'),
                meta: { title: '类型配置' }
            },
            {
                path: 'plateform',
                name: '平台配置',
                component: () =>
                    import ('@/views/configure/plateform'),
                meta: { title: '平台配置' }
            },
            {
                path: 'law',
                name: '律所配置',
                component: () =>
                    import ('@/views/configure/law'),
                meta: { title: '律所配置' }
            },
            {
                path: 'province',
                name: '省份配置',
                component: () =>
                    import ('@/views/configure/province'),
                meta: { title: '省份配置' }
            },
            {
                path: 'reclaim',
                name: '维权回收配置',
                component: () =>
                    import ('@/views/configure/reclaim'),
                meta: { title: '维权回收配置' }
            }
        ]
    },

    {
        path: '/program',
        component: Layout,
        redirect: '/program/programDashboard',
        meta: { title: '片方资料库', icon: 'example' },
        children: [
            // 维权业务 - 欢迎页
            {
                path: 'programDashboard',
                name: '维权业务',
                component: () =>
                    import ('@/views/program/programDashboard'),
                meta: { title: '维权业务' }
            },
            {
                path: 'claimList',
                component: () =>
                    import ('@/views/program/claimList/index'),
                meta: { title: '领权节目列表', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/program/claimList/claimList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: 'claimDetail',
                        component: () =>
                            import ('@/views/program/claimList/claimDetail'),
                        meta: { title: '领权详情' }
                    },
                    {
                        path: 'priceApproval',
                        component: () =>
                            import ('@/views/program/claimList/priceApproval'),
                        meta: { title: '价格核定' }
                    },
                ]
            },
            {
                path: 'claimHandle',
                component: () =>
                    import ('@/views/program/claimHandle/index'),
                meta: { title: '领权办理', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/program/claimHandle/claimHandle'),
                        meta: { title: '列表' },
                    },
                    {
                        path: 'handleProcess',
                        component: () =>
                            import ('@/views/program/claimHandle/handleProcess'),
                        meta: { title: '办理流程' }
                    },
                    {
                        path: 'purchase',
                        component: () =>
                            import ('@/views/program/claimHandle/purchase/index'),
                        meta: { title: '采购单详情' },
                        children: [{
                                path: '/',
                                component: () =>
                                    import ('@/views/program/claimHandle/purchase/purchase'),
                                meta: { title: '' },
                            },
                            {
                                path: 'copyAnalysis',
                                component: () =>
                                    import ('@/views/program/claimHandle/purchase/copyAnalysis'),
                                meta: { title: '版权排查分析' }
                            },
                            // {
                            //     path: 'priceAnalysis',
                            //     component: () => import('@/views/program/claimHandle/purchase/priceAnalysis'),
                            //     meta: { title: '定价分析' }
                            // }
                        ]
                    }
                ]
            },
            {
                path: 'dueList',
                name: '领权期至',
                component: () =>
                    import ('@/views/program/dueList/index'),
                meta: { title: '领权期至', icon: 'form' }
            },
            // {
            //     path: 'payList',
            //     name: '付款列表',
            //     component: () => import('@/views/program/payList/index'),
            //     meta: { title: '付款列表', icon: 'form' }
            // },
            {
                path: 'dataBase',
                redirect: '/program/dataBase/index',
                component: () =>
                    import ('@/views/program/dataBase/index'),
                meta: { title: '' },
                children: [{
                        path: '/',
                        name: 'dataDetail',
                        component: () =>
                            import ('@/views/program/dataBase/dataDetail'),
                        meta: { title: '片方资料库' }
                    },
                    {
                        path: 'config',
                        name: 'config',
                        component: () =>
                            import ('@/views/program/dataBase/config'),
                        meta: { title: '定价配置' }
                    }
                ]
            }
        ]
    },

    {
        path: '/authorization',
        component: Layout,
        redirect: '/authorization/preAuthDashboard',
        meta: { title: '授权管理', icon: 'example' },
        children: [{
                path: 'preAuthDashboard',
                name: '授权管理',
                component: () =>
                    import ('@/views/authorization/preAuthDashboard'),
                meta: { title: '授权管理' }
            },
            {
                path: 'authoPlatform',
                component: () =>
                    import ('@/views/authorization/authPlatform/index'),
                meta: { title: '授权平台管理', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/authorization/authPlatform/list'),
                        meta: { title: '', icon: 'form' }
                    },
                    {
                        path: 'library',
                        component: () =>
                            import ('@/views/authorization/authPlatform/library'),
                        meta: { title: '授权节目库', icon: 'form' }
                    },
                    {
                        path: 'replace',
                        component: () =>
                            import ('@/views/authorization/authPlatform/replace'),
                        meta: { title: '节目更换', icon: 'form' }
                    },
                ]
            },
            {
                path: 'preAuth',
                component: () =>
                    import ('@/views/authorization/preAuth/index'),
                meta: { title: '预授权列表', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/authorization/preAuth/list'),
                        meta: { title: '', icon: 'form' }
                    },
                    {
                        path: 'create',
                        component: () =>
                            import ('@/views/authorization/preAuth/create'),
                        meta: { title: '生成节目单', icon: 'form' }
                    },
                    {
                        path: 'detail',
                        component: () =>
                            import ('@/views/authorization/preAuth/detail'),
                        meta: { title: '节目单详情', icon: 'form' }
                    },
                    {
                        path: 'sales',
                        component: () =>
                            import ('@/views/authorization/preAuth/sales'),
                        meta: { title: '售价评估', icon: 'form' }
                    },
                    {
                        path: 'statistics',
                        component: () =>
                            import ('@/views/authorization/preAuth/statistics'),
                        meta: { title: '节目统计', icon: 'form' }
                    }
                ]
            },
            {
                path: 'authHandle',
                component: () =>
                    import ('@/views/authorization/authHandle/index'),
                meta: { title: '授权办理', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/authorization/authHandle/list'),
                        meta: { title: '', icon: 'form' }
                    },
                    {
                        path: 'authDetail',
                        component: () =>
                            import ('@/views/authorization/authHandle/authDetail'),
                        meta: { title: '授权节目单详情', icon: 'form' }
                    },
                    {
                        path: 'handleProcess',
                        component: () =>
                            import ('@/views/authorization/authHandle/handleProcess'),
                        meta: { title: '办理流程' }
                    }

                ]
            },
            {
                path: 'contractList',
                component: () =>
                    import ('@/views/authorization/contractList/index'),
                meta: { title: '履约合同列表', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/authorization/contractList/contractList'),
                        meta: { title: '列表' }
                    },
                    {
                        path: 'detail',
                        component: () =>
                            import ('@/views/authorization/contractList/contractDetail'),
                        meta: { title: '详情' }
                    },
                    {
                        path: 'priceApproval',
                        component: () =>
                            import ('@/views/authorization/contractList/priceApproval'),
                        meta: { title: '价格核定' }
                    }
                ]
            },
            {
                path: 'offlineManage',
                name: '节目下线管理',
                component: () =>
                    import ('@/views/authorization/offlineManage/index'),
                meta: { title: '节目下线管理', icon: 'form' }
            },
            {
                path: 'discount',
                name: '折扣管理',
                component: () =>
                    import ('@/views/authorization/discount/index'),
                meta: { title: '折扣管理', icon: 'form' }
            },
        ],
    },

    {
        path: '/support',
        component: Layout,
        redirect: '/support/certificate',
        meta: { title: '授权支持', icon: 'example' },
        children: [{
                path: 'certificate',
                component: () =>
                    import ('@/views/support/certificate/index'),
                meta: { title: '授权书支持', icon: 'form' },
                children: [{
                    path: '/',
                    component: () =>
                        import ('@/views/support/certificate/list'),
                    meta: { title: '列表' },
                }]
            },
            {
                path: 'confirm',
                component: () =>
                    import ('@/views/support/confirm/index'),
                meta: { title: '节目单确认', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/support/confirm/list'),
                        meta: { title: '', icon: 'form' }
                    },
                    {
                        path: 'detail',
                        component: () =>
                            import ('@/views/support/confirm/detail'),
                        meta: { title: '节目单详情', icon: 'form' }
                    }
                ]
            },
            {
                path: 'replaceManage',
                component: () =>
                    import ('@/views/support/replaceManage'),
                meta: { title: '节目变更确认', icon: 'form' },
            },
        ]
    },

    {
        path: '/applet',
        component: Layout,
        redirect: '/applet/appletDashboard',
        meta: { title: '小程序管理', icon: 'example' },
        children: [{
                path: 'appletDashboard',
                name: '小程序管理',
                component: () =>
                    import ('@/views/applet/appletDashboard'),
                meta: { title: '小程序管理' }
            },
            {
                path: 'appletUser',
                component: () =>
                    import ('@/views/applet/appletUser/index'),
                meta: { title: '访问人员', icon: 'form' },
                children: [{
                    path: '/',
                    component: () =>
                        import ('@/views/applet/appletUser/list'),
                    meta: { title: '列表' },
                }]
            },
            {
                path: 'ticketManager',
                component: () =>
                    import ('@/views/applet/ticketManager/index'),
                meta: { title: '工单列表', icon: 'form' },
                children: [{
                        path: '/',
                        component: () =>
                            import ('@/views/applet/ticketManager/list'),
                        meta: { title: '工单列表', icon: 'form' }
                    },
                    {
                        path: 'detail',
                        component: () =>
                            import ('@/views/applet/ticketManager/info'),
                        meta: { title: '详情', icon: 'form' }
                    },
                    {
                        path: 'programDetail',
                        component: () =>
                            import ('@/views/applet/ticketManager/programDetail'),
                        meta: { title: '节目单详情', icon: 'form' }
                    }
                ]
            },
            {
                path: 'whiteUser',
                component: () =>
                    import ('@/views/applet/whiteUser/index'),
                meta: { title: '白名单', icon: 'form' },
                children: [{
                    path: '/',
                    component: () =>
                        import ('@/views/applet/whiteUser/list'),
                    meta: { title: '列表' },
                }]
            },
            {
                path: 'emailBatch',
                component: () =>
                    import ('@/views/applet/batch/index'),
                meta: { title: '邮件发送', icon: 'form' },
                children: [{
                    path: '/',
                    component: () =>
                        import ('@/views/applet/batch/list'),
                    meta: { title: '列表' },
                }]
            },
            {
                path: 'classificationConfigCount',
                component: () =>
                    import ('@/views/applet/classificationConfigCount/index'),
                meta: { title: '节目单数量配置', icon: 'form' },
                children: [{
                    path: '/',
                    component: () =>
                        import ('@/views/applet/classificationConfigCount/list'),
                    meta: { title: '列表' },
                }]
            },
            {
                path: 'emailBatchForAll',
                component: () =>
                    import ('@/views/applet/batchForAll/index'),
                meta: { title: '邮件发送全量', icon: 'form' },
                children: [{
                    path: '/',
                    component: () =>
                        import ('@/views/applet/batchForAll/list'),
                    meta: { title: '列表' },
                }]
            },
            {
                path: 'emailBatchForCustom',
                component: () =>
                    import ('@/views/applet/batchForCustom/index'),
                meta: { title: '自定义发送节目单', icon: 'form' },
                children: [{
                    path: '/',
                    component: () =>
                        import ('@/views/applet/batchForCustom/list'),
                    meta: { title: '列表' },
                }]
            },
        ]
    },

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    /**
     * 由于钉钉回调函数中不能添加#所以设置为history模式，
     * 但是这种模式放到生产环境一刷新会导致404所以需要后台配置nginx
     *  location / {
            try_files $uri $uri/ /index.html;
        }
     */
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router