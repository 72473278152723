var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _vm.system_type == "copyright"
        ? _c("img", {
            class: { "sidebar-logo": !_vm.collapse },
            attrs: {
              src: require("../../../assets/index_images/logo-icon3.png"),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.system_type == "safeguard"
        ? _c("img", {
            class: { "sidebar-logo": !_vm.collapse },
            attrs: { src: require("../../../assets/index_images/logo2.png") },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          !_vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _c("h1", { staticClass: "sidebar-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }