<template>
<div class="relative" :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="variables.menuBg" :text-color="variables.menuText" :unique-opened="true" active-text-color="#fff" :collapse-transition="false" mode="vertical">
            <sidebar-item v-for="route in routes" :key="route.id" :item="route" :base-path="route.resourceUrl" />
        </el-menu>
    </el-scrollbar>
    <img class="logo-back" v-if="system_type == 'copyright'" src="../../../assets/index_images/logo-back-icon2.png" />
    <img class="logo-back" v-if="system_type == 'safeguard'"  src="../../../assets/index_images/logo-back-icon.png" />
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import {
    getCurrentResource
} from '@/api/configure'
import {
    menuIndex
} from '@/layout/components/Sidebar/menusConfigure'

export default {
    data() {
        return {
            appType: process.env.VUE_APP_TYPE,
            logo: '',
            routeList: [],
            routes: [],
            obj: {},
            system_type: process.env.VUE_APP_SYSTEM_TYPE
        }
    },
    components: {
        SidebarItem,
        Logo
    },
    computed: {
        ...mapGetters([
            'sidebar'
        ]),
        showLogo() {
            return this.$store.state.settings.sidebarLogo
        },
        variables() {
            return variables
        },
        isCollapse() {
            return !this.sidebar.opened
        },
        activeMenu() {
            // const route = this.$route
            // const { meta, path } = route
            // // if set path, the sidebar will highlight the path you set
            // if (meta.activeMenu) {
            //     return meta.activeMenu
            // }
            // return path
            // return '1'
        }
    },

    mounted() {
        this.getResourceList();
        if(this.appType == 'copyright') { 
            this.logo = require('./../../../assets/index_images/siderbar_01.png')
        }
        if(this.appType == 'safeguard') {
            this.logo = require('./../../../assets/index_images/siderbar_02.png')
        }
    },
    watch: {
        $route:{
            handler(val,oldval){
                console.log("handler  ==  ", val, oldval)
                if(val.name !== oldval.name) {
                    this.handleRouter(val)
                }
            },
            // 深度观察监听
            deep: false
        }
    },
    methods: {
        //获取资源
        getResourceList() {
            getCurrentResource().then((res) => {
                this.routeList = res.data.data;
                const _route = this.$route
                this.handleRouter(_route)
            }).catch((err) => {
                console.log(err)
            })
        },

        // 根据当前的 route 处理获取的数据
        handleRouter(route) {
            let _list = []
            let _model = menuIndex[route.path]

            if (!_model) {
                this.routes = []
                this.setData()
                return 
            }
            this.routeList.forEach(element => {
                const _key = element.resourceName
                if(_model[_key]) {
                    _list.push(element)
                }
            });
            

            sessionStorage.setItem('routeList', JSON.stringify(_list)); 
            
            this.routes = _list
            this.dealResource(this.routes, 0);
        },

        // set Data 
        setData() {
            // let _list = JSON.parse(sessionStorage.routeList)  
            // this.routes = _list
            // this.dealResource(this.routes, 0);
            if (sessionStorage.routeList) {
                let _list = JSON.parse(sessionStorage.routeList)  
                this.routes = _list
                this.dealResource(this.routes, 0);
            }
            // let _list = []
            // const _name = this.findParentItemName(route)
            // for(let key in menuIndex) {
            //     if(menuIndex[key][_name]){
            //         let _model = menuIndex[key]
            //         this.routeList.forEach(element => {
            //             const _key = element.resourceName
            //             if(_model[_key]) {
            //                 _list.push(element)
            //             }
            //         });
                    
            //         this.routes = _list
            //         this.dealResource(this.routes, 0);
            //         return 
            //     }
            // }
        },

        // 寻找父级路由，并进入 dealResource 重新配置
        findParentItemName(route) {
            for(let i = 0, l = this.routeList.length; i<l; i++) {
                const _item = this.routeList[i]
                for(let n = 0, m = _item.children.length; n<m; n++) {
                    const _it = _item.children[n]
                    if(route.path == _it.resourceUrl) {
                        return _item.resourceName
                    }
                }
            }
        },

        //给每个菜单添加index值
        dealResource(data, index) {
            for (let i = 0; i < data.length; i++) {
                if (index === 0) {
                    data[i]['index'] = Number(i + 1) + '';
                } else {
                    data[i]['index'] = index + '-' + Number(i + 1);
                }
                this.obj[data[i].resourceUrl] = data[i];
                data[i].children = data[i].children || [];
                if (data[i].children.length > 0) {
                    this.dealResource(data[i].children, data[i].index)
                }
            }
        },
    }
}
</script>
<style>
    .relative{
        position: relative;
    }
    .logo-back{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 230px;
        height: 230px;
        z-index: -10000;
    }

</style>
