import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 500000, // request timeout
    proxy: false
})
// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        let token;
        if (localStorage.getItem('access_token')) {
            token = localStorage.getItem('access_token');
            config.headers = {
                'Authorization': 'bearer ' + token
            }
        } else {
            token = null;
        }
        if (config.method == 'post') {
            config.params = {};
        }

        return config

    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data;
        if (res.code == 0 || res.Status == 'OK'||res.code==1) {
            return response
        }else if(res.code==10401){
            Message({
                showClose: false,
                message: '您的账号已有其他用户登录，请注意账号保护！',
                type: 'error'
            });
            localStorage.removeItem("access_token")
            setTimeout(() => {
                location.reload();
            }, 3000)
        } else {
            Message({
                showClose: false,
                message: '服务器开小差，请稍后再试',
                type: 'error'
            });
            return Promise.reject(new Error(res.msg || 'Error'))
        }

    },
    error => {
        console.log("handleLogin  ==  ", error)
        if (error.response&&error.response.status == '401') {
            Message({
                showClose: false,
                message: '用户未登录,请重新登录！',
                type: 'error'
            });
            localStorage.removeItem("access_token")
            setTimeout(() => {
                location.reload();
            }, 3000)

        } else {
            Message({
                showClose: false,
                message: '服务器开小差，请稍后再试',
                type: 'error'
            });
        }

        return Promise.reject(error)

    }
)

export default service
