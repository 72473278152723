module.exports = {

    title: process.env.VUE_APP_SYSTEM_TYPE == 'copyright' ? '领头羊领授权管理平台' : '啄木鸟领维权管理平台',

    systemType: process.env.VUE_APP_SYSTEM_TYPE,

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: false,

    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: true,
    userService: '/v1',
    userServicev2: '/v2'
}