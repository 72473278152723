<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    index:{
      type:String,
      default:''
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    activeIcon:{
      type:String,
      default:''
    }
  },
  render(h, context) {
    const { icon, title,activeIcon } = context.props
    const vnodes = [];
    if (icon) {
      vnodes.push(<svg-icon class-name="default-icon" icon-class={icon}/>)
      vnodes.push(<svg-icon class-name="selected-icon" icon-class={activeIcon}/>)
    }
    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>
