<template>
<div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <img v-if="system_type=='copyright'" src="../../../assets/index_images/logo-icon3.png" :class="{'sidebar-logo':!collapse}" />
    <img v-if="system_type=='safeguard'" src="../../../assets/index_images/logo2.png" :class="{'sidebar-logo':!collapse}" />
    <transition name="sidebarLogoFade">
        <router-link v-if="!collapse" key="collapse" class="sidebar-logo-link" to="/">
            <h1 class="sidebar-title">{{title}}</h1>
        </router-link>
    </transition>
</div>
</template>

<script>
export default {
    name: 'SidebarLogo',
    props: {
        collapse: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
           system_type: process.env.VUE_APP_SYSTEM_TYPE,
           title: ''
        }
    },

    mounted() {
        if(this.system_type == 'copyright') {
            this.logo = require('../../../assets/index_images/logo_02.png')
            this.title = '领头羊领授权平台'
        }
        if(this.system_type == 'safeguard') {
            this.logo = require('../../../assets/index_images/logo_01.png')
            this.title = '啄木鸟领维权平台'
        }
    },
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

.sidebar-logo-container {
    position: relative;
    width: 100%;
    height: 68px;
    background: #21232C;
    text-align: center;
    overflow: hidden;
    display: flex;
    img{
        width: 32px;
        margin: 12px 10px 0 16px!important;
        height: 32px;
    }
    .sidebar-logo {
        margin:12px 0px 0 12px;
    }
    & .sidebar-logo-link {
        height: 100%;
        & .sidebar-title {
            display: inline-block;
            margin: 0;
            color: #fff;
            font-weight: 500;
            line-height: 50px;
            font-size: 17px;
            font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
            vertical-align: middle;
            margin-top: 4px;
        }
    }
    &.collapse {
        .sidebar-logo {
            margin-right: 0px;
        }
    }
}
</style>
