export let menuIndex = {
    '/dashboard': {
        "查询服务": true,
    },
    '/program/programDashboard': {
        "领权管理": true,
        "授权业务支持": true,
        "维权业务支持": true,
    },
    '/authorization/preAuthDashboard': {
        "授权管理": true,
    },
    '/rights/rightsDashboard': {
        "维权业务监测": true,
        "维权业务执行": true,
        "律所任务": true,
        "视频任务": true,
        "维权业务数据图": true,
    },
    '/media/mediaDashboard': {
        "介质媒资库": true,
    },
    '/copyrightSettlement/copyrightSettlementDashboard': {
        "版权结算管理": true,
    },
    '/settlement/settleIndex': {
        "维权结算管理": true,
    },
    '/configure/configureDashboard': {
        "业务配置": true,
    },
    '/applet/appletDashboard': {
        "小程序管理": true,
    },
}