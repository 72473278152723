import request from '@/utils/request'
import settings from '../settings';

export function login(params) {
    return request({
        url: settings.userService+'/login/account',
        responseType: 'json',
        method:'post',
        data:params
    })
}
//获取用户详情
export function getUserInfo() {
    return request({
        url: settings.userService+'/user/detail',
        method:'get',
    })
}
//通过钉钉登陆获取token
export function dingLoginGetToken(params) {
    return request({
        url: settings.userService+'/login/dingDing/'+params.code,
        responseType: 'json',
        method:'post'
    })
}