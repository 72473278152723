var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "12px", "margin-left": "10px" },
          attrs: { type: "primary", plain: "", size: "mini" },
          on: {
            click: function ($event) {
              return _vm.goIndexPage()
            },
          },
        },
        [_c("i", { staticClass: "el-icon-s-home" }), _vm._v("首页")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container",
              attrs: { trigger: "click", placement: "bottom-start" },
            },
            [
              _c("span", { staticClass: "avatar-wrapper" }, [
                !_vm.avatar
                  ? _c("span", { staticClass: "avatar-name" }, [
                      _vm._v(
                        _vm._s(_vm.name ? _vm.spliceNameFn(_vm.name) : "")
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.avatar
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.name || ""))]),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_vm._v("\n                    退出\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }