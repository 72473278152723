<template>
<div class="navbar">
    <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <!-- <breadcrumb class="breadcrumb-container" /> -->

    <el-button @click="goIndexPage()" style="margin-top: 12px; margin-left: 10px" type="primary" plain size="mini"><i class="el-icon-s-home"></i>首页</el-button>

    <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="click" placement="bottom-start">
            <span class="avatar-wrapper">
                <span class="avatar-name" v-if='!avatar'>{{name?spliceNameFn(name):''}}</span>
                <img  class="user-avatar" v-if='avatar' :src="avatar+'?imageView2/1/w/80/h/80'">
                <span>{{name||''}}</span>
                <i class="el-icon-caret-bottom" />
            </span>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
                <el-dropdown-item  @click.native="logout">
                    退出
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import {getUserInfo } from '@/api/login'
import {spliceName} from '@/utils/index';// 引入防抖和截取第一个字

export default {
    data() {
        return {  
        }
    },
  components: {
    Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'name'
    ])
  },
  mounted(){
      this.getUser();
  },
  methods: {
        // 跳转到首页
        goIndexPage() {
            this.$router.push(`/dashboard`);
        },
        //切割第一位字
        spliceNameFn(name){
            let _name = name + ''
            return _name.substr(0,1)
        },  
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        logout() {
            localStorage.removeItem("access_token");
            location.reload();
        },
        getUser(){
            //获取用户信息
            getUserInfo().then((res)=>{
                if(res.data.code==0){
                    this.$store.commit('user/SET_TOKEN',localStorage.getItem('access_token'));
                    this.$store.commit('user/SET_NAME',res.data.data.name);
                    this.$store.commit('user/SET_AVATAR',res.data.data.avatar);
                    sessionStorage.setItem('roles',JSON.stringify(res.data.data.resourceVOList||[]));
                }else{
                    this.$message.error('获取用户信息失败,请稍后再试')
                }
            }).catch((error)=>{
                console.log(error)
            })
        }
         
    }
}
</script>

<style lang="scss" scoped>
.navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    .hamburger-container {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background .3s;
        -webkit-tap-highlight-color: transparent;
        &:hover {
            background: rgba(0, 0, 0, .025)
        }
    }
    .breadcrumb-container {
        float: left;
    }
    .right-menu {
        float: right;
        height: 100%;
        display: flex;
        align-items: center;
        &:focus {
            outline: none;
        }
        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            vertical-align: text-bottom;
            &.hover-effect {
                cursor: pointer;
                transition: background .3s;
                &:hover {
                    background: rgba(0, 0, 0, .025)
                }
            }
        }
        .avatar-container {
            margin-right: 30px;
            cursor: pointer;
            .avatar-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                .user-avatar {
                    cursor: pointer;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    margin-right: 8px;
                    overflow: hidden;
                }
                .el-icon-caret-bottom {
                    cursor: pointer;
                    font-size: 12px;
                    margin-left: 12px;
                }
                .avatar-name{
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    background: rgba(11,123,255,0.2);
                    border-radius: 50%;
                    color: #0B7BFF;
                    text-align: center;
                    line-height: 28px;
                    margin-right: 8px;
                }
            }
        }
    }
}
</style>
