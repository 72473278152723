/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * 秒 转换为 时:分:秒
 * @param {number} seconds
 */
export function secondToTime(seconds) {
    if (!seconds && seconds !== 0) {
        return '-'
    }
    var theTime = parseInt(seconds); // 秒
    var middle = 0; // 分
    var hour = 0; // 小时

    if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
        }
    }
    var result = "" + parseInt(theTime) + "秒";
    if (middle > 0) {
        result = "" + parseInt(middle) + "分" + result;
    }
    if (hour > 0) {
        result = "" + parseInt(hour) + "小时" + result;
    }
    return result;
}


/**
 * 文件大小的单位转换 B 转换为 KB,MB,GB
 * @param {number} limit
 */
export function formatFileSize(limit) {
    if (!limit) {
        return 0
    }
    let size = "";
    if (limit < 0.1 * 1024) { //如果小于0.1KB转化成B  
        size = limit.toFixed(2) + "B";
    } else if (limit < 0.1 * 1024 * 1024) { //如果小于0.1MB转化成KB  
        size = (limit / 1024).toFixed(2) + "KB";
    } else if (limit < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
        size = (limit / (1024 * 1024)).toFixed(2) + "MB";
    } else { //其他转化成GB  
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }

    let sizestr = size + "";
    const len = sizestr.indexOf("\.");
    const dec = sizestr.substr(len + 1, 2);
    if (dec == "00") { //当小数点后为00时 去掉小数部分  
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        return value.toString().padStart(2, '0')
    })
    return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
    const search = url.split('?')[1]
    if (!search) {
        return {}
    }
    return JSON.parse(
        '{"' +
        decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
        '"}'
    )
}
//输入商户名称和电话防抖搜索避免请求次数过多
//防抖
let timer = null;
export function debounce(fn, wait) {
    return function() {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn();
        }, wait)
    }
}
export function spliceName(name) {
    return (name == null || name == "") ? "无" : name.slice(0, 1);
}
export function formatTimeStr(time, str) {
    if (!time) {
        return '';
    }
    let date = new Date(time);
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
    const second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()

    return [year, month, day].map(formatNumber).join(str) + ' ' + hour + ':' + minute + ':' + second
}
//日期格式化通用
/**
 * @param time 传入的标准时间 或 时间字符串 '2018-10-10'，传入连接符号
 * 日期格式化通用，返回 yyyy-MM-dd   yyyy/MM/dd  yyyy.MM.dd
 */
export function getDayPubFormat(time, str) {
    if (!time) {
        return null
    }
    const date = new Date(time)
    const year = date.getFullYear()
    const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    return year + str + month + str + day
}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}

export function spliceDot(name) {

    if (name == null || name == "") {

        return

    } else {

        let arr = name.split('.');
        arr.splice([arr.length - 1], 1);
        return arr.join();

    }

}